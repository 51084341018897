import React, { useState, useEffect } from 'react'
import { PageProps, navigate } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import styled, { ThemeProvider } from 'styled-components'
import {
  Market,
  toCssPrefix,
  Image,
  Button,
  useResolution,
  breakpoints,
  Anchor,
  useShoppingCart,
  useAuth,
} from '@ecommerce/shared'
import Layout from '../../components/Layout'
import SectionTitle from '../../components/Moments/SectionTitle'
import Seo from '../../components/Seo'
import ShareModal from '../../components/Recipes/ShareModal'
import { Icon } from '../../components/Icon/Icon'
import useTheme from '../../hooks/useTheme'
import { NewsItem } from '../../graphql/contentfulTypes'
import Video from '../../components/Video'
import withPageTransition from '../../components/withPageTransition'
import { sendPageViewEvent } from '../../utils/events'

type Props = PageProps<{}, { data: NewsItem; allNews: NewsItem[]; cities: Market[] }>

const { cssPrefix, toPrefix } = toCssPrefix('NewsDetail__')

const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.brand.default};
  background: ${({ theme }) => theme.colors.background.bodyAlternative};
  padding: 20px 0 120px;
  position: relative;
  min-height: 100vh;

  .${cssPrefix} {
    &inner {
      display: flex;
      flex-direction: column;
      max-width: 1520px;
      margin: auto;

      &-left {
        background: ${({ theme }) => theme.colors.white};
        position: relative;
      }

      &-right {
        margin-top: 50px;
      }
    }

    &go-back {
      display: flex;
      align-items: center;
      width: 73px;
      position: absolute;
      top: 70px;
      z-index: 1;

      svg {
        width: 26px;
        height: 17px;
      }

      span {
        width: auto;
        font-size: 14px;
        color: ${({ theme }) => theme.colors.black};
        cursor: pointer;
      }
    }

    &image {
      width: 100%;
      height: 210px;
      object-fit: cover;
    }

    &content {
      padding: 40px 20px 30px;
      color: ${({ theme }) => theme.colors.bodyText};
    }

    &title {
      font-size: 28px;
      line-height: 30px;
      margin: 0;
    }

    &author {
      font-size: 14px;
      font-style: italic;
      color: ${({ theme }) => theme.colors.platinum80};
    }

    &text {
      font-size: 17px;
      line-height: 25px;
      margin: 40px 0;
      text-align: justify;
    }

    &videos-list {
      padding-bottom: 0;
    }

    &share-button {
      width: 155px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 16px;
      padding-left: 16px;
      margin-bottom: 40px;
      margin-left: 20px;

      &-icon {
        width: 20px;
        height: 20px;
        filter: invert(1);
      }
    }

    &navigation {
      width: 335px;
      padding: 18px 0;
      border: 2px solid ${({ theme }) => theme.colors.grey};
      margin: auto auto 35px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-button {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: bold;
        background: none;
        border: none;
        padding: 0;
        white-space: nowrap;
        cursor: pointer;

        svg {
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }

        &:last-child {
          svg {
            margin-left: 10px;
            margin-right: 0;
          }
        }
      }
    }
  }

  @media screen and (${breakpoints.tabletLandscape.min}) {
    padding: 20px 25px 120px;

    .${cssPrefix} {
      &image {
        height: 390px;
      }

      &content {
        padding: 40px 30px;
        border-radius: ${({ theme }) => theme.borderRadius};
        box-shadow: ${({ theme }) => theme.boxShadow.lvlOne};
        margin: 0 25px;
        position: relative;
        top: -100px;
        background: ${({ theme }) => theme.colors.white};
      }

      &share-button {
        position: absolute;
        top: 20px;
        right: 20px;
      }

      &navigation {
        margin-bottom: 45px;
      }
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    padding: 40px 45px 130px;

    .${cssPrefix} {
      &inner {
        flex-direction: row;

        &-left {
          width: 65%;
          padding: 50px 0 50px 30px;
        }

        &-right {
          width: 35%;
          margin-top: 0;
          background: ${({ theme }) => theme.colors.white};
          padding: 50px 30px;
        }
      }

      &share-button {
        top: 70px;
      }

      &videos-column {
        h2 {
          font-size: 24px;
          margin-top: 0;
        }

        &-video {
          width: 100%;
          height: 240px;
          margin-bottom: 30px;
          border-radius: ${({ theme }) => theme.borderRadius};

          &-title,
          &-description {
            left: 15px;
          }

          &-title {
            font-size: 20px;
            bottom: 50px;
          }

          &-description {
            font-size: 12px;
            bottom: 20px;
          }
        }
      }
    }
  }
`

const NewsTemplate = ({ pageContext: { data, allNews }, location }: Props) => {
  const [showShareModal, setShowShareModal] = useState(false)
  const { isDesktop } = useResolution()

  const { getConnectifCart } = useShoppingCart()
  const { getEntityInfo } = useAuth()

  useEffect(() => {
    const entityInfo = getEntityInfo()
    const cart = getConnectifCart()
    sendPageViewEvent({ cart, entityInfo, title: 'Noticias' })
  }, [])
  const {
    parentMoment,
    metaTitle,
    metaDescription,
    metaKeywords,
    pageOrder,
    descriptionImage,
    title,
    authorAndDate,
    text,
    capsulesTitle,
    capsules,
    socialMediaShareMessage,
  } = data
  const { mergeThemes } = useTheme(parentMoment?.theme?.contentful_id)

  const themeColor = parentMoment?.theme?.brandDefault

  const previousNews = allNews[pageOrder - 2]
  const nextNews = allNews[pageOrder]
  const prevSlug = previousNews && previousNews?.slug ? `/noticias/${previousNews.slug}` : null
  const nextSlug = nextNews?.slug ? `/noticias/${nextNews.slug}` : null

  return (
    <ThemeProvider theme={mergeThemes}>
      <Layout title={metaTitle}>
        <Wrapper>
          {isDesktop && (
            <Anchor className={toPrefix('go-back')} onClick={() => navigate(`../../`)}>
              <Icon iconId="arrow_left" />
              <span>Volver</span>
            </Anchor>
          )}
          {showShareModal && (
            <ShareModal
              title="Comparte esta noticia en:"
              message={socialMediaShareMessage}
              onClose={() => setShowShareModal(false)}
            />
          )}
          <Seo description={metaDescription} keywords={metaKeywords}>
            {themeColor && <meta name="theme-color" content={themeColor} />}
            <meta property="og:type" content="website" />
            <meta name="twitter:card" content="summary" />
            <meta name="og:title" content={metaTitle} />
            <meta name="og:description" content={metaDescription} />
            <meta name="og:image" content={descriptionImage?.file.url} />
            <meta name="twitter:image:alt" content={metaTitle} />
            <meta name="og:url" content={location.href} />
          </Seo>
          <SectionTitle text="NOTICIAS" />
          <div className={toPrefix('inner')}>
            <div className={toPrefix('inner-left')}>
              <Image className={toPrefix('image')} src={descriptionImage?.file.url} alt={title} />
              <div className={toPrefix('content')}>
                <h1 className={toPrefix('title')}>{title}</h1>
                <p className={toPrefix('author')}>{authorAndDate}</p>
                <div className={toPrefix('text')}>{documentToReactComponents(text?.json)}</div>
              </div>
              <Button onClick={() => setShowShareModal(true)} className={toPrefix('share-button')}>
                <Icon className={toPrefix('share-button-icon')} iconId="share" />
                Compartir
              </Button>
              <div className={toPrefix('navigation')}>
                <button
                  className={toPrefix('navigation-button')}
                  onClick={() => (prevSlug ? navigate(prevSlug) : null)}
                  type="button"
                  disabled={!prevSlug}
                >
                  <Icon iconId="chevron_left" />
                  NOTICIA PREVIA
                </button>
                <button
                  className={toPrefix('navigation-button')}
                  onClick={() => (nextSlug ? navigate(nextSlug) : null)}
                  type="button"
                  disabled={!nextNews}
                >
                  PRÓXIMA NOTICIA
                  <Icon iconId="chevron_right" />
                </button>
              </div>
            </div>
            <div className={toPrefix('inner-right')}>
              {isDesktop ? (
                <div className={toPrefix('videos-column')}>
                  <h2>{capsulesTitle}</h2>
                  <div className={toPrefix('videos-column-items')}>
                    {capsules?.map((capsule) => (
                      <Video
                        key={capsule.contentful_id}
                        className={toPrefix('videos-column-video')}
                        src={capsule.video.file.url}
                        poster={capsule.videoPoster.file.url}
                        description={capsule.videoDescription}
                        title={capsule.title}
                        preload="none"
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Wrapper>
      </Layout>
    </ThemeProvider>
  )
}

export default withPageTransition(NewsTemplate)
